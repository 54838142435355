<template>
   <b-card
      title="Welcome User"
    >
      <b-card-text>
        This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.
      </b-card-text>
    </b-card>
</template>

<script>
import { BRow, BCol, BCard, BCardText, } from 'bootstrap-vue'
export default {
  name: 'Home',
  components: {
    BRow,
    BCol,
    BCard, 
    BCardText,

  },
  data() {
    return {
      data: {},
    }
  },
}
</script>
